.external-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
}

.external-link img {
    width: 24px;
    height: 24px;
    transition: opacity 0.2s;
}

.external-link:hover img {
    opacity: 0.8;
} 