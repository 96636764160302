body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Merienda';
  src: local('MyFont'), url(./fonts/Merienda/Merienda-VariableFont_wght.ttf) format('truetype');
}

.header-font {
  font-size: 4.45em;
}

@media (max-width: 768px) {
  .header-font {
    font-size: 2.65em;
  }
}

.accordion-requirement {
  --bs-accordion-btn-bg: transparent !important;
  --bs-accordion-active-color: #000000 !important;
  --bs-accordion-active-bg: #A6B8C9 !important;
}

/*.accordion-result {*/
/*  --bs-accordion-btn-bg: transparent !important;*/
/*}*/
.accordion-result {
  --bs-accordion-btn-bg: transparent !important;
  --bs-accordion-active-color: #222222 !important;
  --bs-accordion-active-bg: #99edc3 !important;
}
